import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { PalletView } from "../components/PalletView";
import { DataList } from "../components/dataList/DataList";
import InfoList from "../components/InfoList";
import {
	getLatestChart,
	getLatestPalletInfo,
	getClickedPallet,
	getSectorInfo,
	setDecisionBySector,
	updateAfterDecision,
	pointCloudDownload,
} from "../store/opPanelSlice";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { ReactComponent as PalletDirection } from "../icons/palletDirectionSmall.svg";
import { PointCloudView } from "../components/PointCloudView";

const PanelWrapper = styled.div`
	display: flex;
	flex-direction: ${(props) => (props.isSmall ? "column" : "row")};
	justify-content: space-between;
	padding: 2rem 24px;
	background: #DBE2F1;
	gap: 1rem;
`;

const DivWrapper = styled.div`
	display: flex;
	width: calc((100vh - 216px - 42px) / 11 * 14 + 32px);
	flex-direction: column;
	gap: 1rem;
`;

const RotatableWrapper = styled.div`
	position: relative;
	background: ${(props) => props.borderColor};;
	${(props) => ((props.angle / 90) % 2 ? "width" : "height")}: 
	calc(100vh - 216px);
	transform: rotate(${(props) => props.angle}deg);
	border: 16px solid ${(props) => props.borderColor};;
	border-top: 26px solid ${(props) => props.borderColor};;
	border-radius: 6px;
`;

const ContainerWrapper = styled.div`
	background: #EBEFF5;
	border-radius: 6px;
	height: calc(100vh - 216px);
	width: calc((100vh - 216px - 42px) / 11 * 14 + 32px);
	${(props) =>
		(props.angle / 90) % 2
			? `
		padding: calc(((100vh - 216px) - ((100vh - 216px - 32px) / 14 * 11 + 42px)) / 2) calc(((100vh - 216px - 42px) / 11 * 14 + 32px - (100vh - 216px)) / 2) ;
		`
			: ""}
`;

const DirectionIcon = styled(PalletDirection)`
	position: absolute;
	fill: ${(props) => props.arrowColor};
	top: -18px;
	left: calc(50% - 45px);
	width: 90px;
	height: 18px;
`;

const Panel = ({ blur, setBlur }) => {
	const {
		uuid,
		isHistoryOnly,
		image,
		chart,
		historyStatus,
		sectorStatus,
		decisionBySector,
		imageCrop,
		pallet,
		cloudStatus,
		scheme,
		batch,
		selectedPointClouds,
	} = useSelector((state) => state.opPanel);
	const [imageCropCoords, setImageCropCoords] = useState(null);
	const [rotationAngle, setRotationAngle] = useState(
		Number(localStorage.getItem("rotationAngle"))
	);
	const isSmall = useMediaQuery("(max-width: 1200px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const dispatch = useDispatch();
	const [isPointCloudOpen, setIsPointCloudOpen] = useState(false);
	const [selectedPointCloud, setSelectedPointCloud] = useState(null);

	useEffect(() => {
		if (isHistoryOnly) {
			dispatch(getLatestChart({ id: uuid }));
		} else {
			dispatch(getLatestPalletInfo({ id: uuid }));
		}
	}, [uuid]);

	const handleMaskClick = (maskCrop, sector_id) => {
		setImageCropCoords(maskCrop);
		dispatch(
			getSectorInfo({
				palletId: pallet.uuid,
				crop: maskCrop,
				sectorId: sector_id,
			})
		);
	};

	const handleChartClick = useCallback(
		(id) => {
			dispatch(getClickedPallet({ id: id }));
		},
		[dispatch]
	);

	const handleRotation = () => {
		setRotationAngle((prev) => {
			const result = (prev + 90) % 360;
			localStorage.setItem("rotationAngle", result);
			return result;
		});
	};

	const positiveDecisionHandler = (palletId) => (sectorId) => (defected) => {
		dispatch(
			setDecisionBySector({
				palletId,
				sectorId,
				decisionDetails: {
					decision: true,
					defect_classes: defected,
				},
			})
		).then(() => {
			dispatch(updateAfterDecision({ palletId }));
		});
	};

	const negativeDecisionHandler = (palletId) => (sectorId) => () => {
		dispatch(
			setDecisionBySector({
				palletId,
				sectorId,
				decisionDetails: {
					decision: false,
				},
			})
		).then(() => {
			dispatch(updateAfterDecision({ palletId }));
		});
	};

	const loadHandler = () => {
		setBlur(false);
	};

	const handlePointCloudClick = useCallback(() => {
		const uuid = pallet?.uuid;
		if (
			selectedPointClouds.find(
				(pointCloud) => pointCloud.palletId === uuid
			)
		) {
			setIsPointCloudOpen(true);
			setSelectedPointCloud(
				selectedPointClouds.find(
					(pointCloud) => pointCloud.palletId === uuid
				)
			);
		} else {
			dispatch(pointCloudDownload({ palletId: uuid })).then((res) => {
				setIsPointCloudOpen(true);
				setSelectedPointCloud(res.payload);
			});
		}
	}, [pallet, selectedPointClouds, dispatch]);

	const infoList = useMemo(
		() => (
			<InfoList
				chart={chart}
				batch={batch}
				cloudStatus={cloudStatus}
				handleRotation={handleRotation}
				handlePointCloudClick={handlePointCloudClick}
				angle={rotationAngle}
				handleChartClick={handleChartClick}
				loaded={historyStatus === "fulfilled"}
			/>
		),
		[
			chart,
			batch,
			handleChartClick,
			historyStatus,
			cloudStatus,
			rotationAngle,
			handlePointCloudClick,
		]
	);

	const [borderColor, arrowColor] = useMemo(() => {
		if (!scheme) return ["#FFFFFF", "#455076"];
		if (scheme.content) return ["#455076", "#FFFFFF"];
		if (
			[
				"heights_status",
				"defects_status",
				"repeat_status",
				"avg_overlimit_status",
				"density_overlimit_status",
			].some((status) => scheme[status] === "restricted")
		) {
			return ["#FB564D", "#FFFFFF"];
		}
		if (
			[
				"heights_status",
				"defects_status",
				"repeat_status",
				"avg_overlimit_status",
				"density_overlimit_status",
			].some((status) => scheme[status] === "allowed")
		) {
			return ["#E6DA00", "#455076"];
		}

		return ["#FFFFFF", "#455076"];
	}, [scheme]);

	return (
		<PanelWrapper isSmall={isSmall || isPortrait}>
			<DivWrapper isSmall={isSmall || isPortrait}>
				<DataList scheme={scheme} />
				<ContainerWrapper angle={rotationAngle}>
					<RotatableWrapper
						borderColor={borderColor}
						angle={rotationAngle}
					>
						<DirectionIcon arrowColor={arrowColor} />
						<PalletView
							blur={blur}
							scheme={scheme}
							imageCrop={imageCropCoords}
							imagesCrop={imageCrop}
							angle={rotationAngle}
							isSectorLoaded={sectorStatus === "fulfilled"}
							decisionBySector={decisionBySector}
							positiveDecisionHandler={positiveDecisionHandler(
								pallet?.uuid
							)}
							negativeDecisionHandler={negativeDecisionHandler(
								pallet?.uuid
							)}
							loadHandler={loadHandler}
							handleMaskClick={handleMaskClick}
							images={[image]}
						/>
					</RotatableWrapper>
				</ContainerWrapper>
			</DivWrapper>
			{infoList}
			{isPointCloudOpen && selectedPointCloud?.cloud && (
				<PointCloudView
					pointCloud={selectedPointCloud.cloud}
					handleClose={() => setIsPointCloudOpen(false)}
				/>
			)}
		</PanelWrapper>
	);
};

export default Panel;
