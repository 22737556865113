import {
	Box,
	Button,
	Table,
	TableRow,
	TableCell,
	TableBody,
	TableHead,
	Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRfidData, getRfidUpdates } from "../../store/rfidSlice";
import styled from "styled-components";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import RfidTable from "./RfidTable";
import DefectsChart from "../DefectsChart";

const SvgWrapper = styled.svg.attrs({
	xmlns: "http://www.w3.org/2000/svg",
	viewBox: "0 0 1400 1100",
})`
	border-radius: 6px;
	flex: 1;
`;

const PalletViewsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: ${(props) => (props.isReversed ? "row-reverse" : "row")};
	gap: 2rem;
`;

const PalletContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: ${(props) => (props.isRotated ? "2rem" : "0.5rem")};
`;

const OrderNumber = styled.div`
	font-size: 7rem;
`;

const RotatedImage = styled.div`
	transform: ${(props) => `rotate(${props.orientation}deg)`};
	height: 100vw;
	aspect-ratio: 14/11;
	width: max-content;
`;

const ChartWrapper = styled.div`
	background-color: #fff;
	border-radius: 6px;
	padding: 10px;
`;

const DrySide = () => {
	const { rfidImages, rfidMasks, scheme, uuid, rfidData } = useSelector(
		(state) => state.rfid
	);
	const { isChartDisplayedSetting, isTableDisplayedSetting } = useSelector(
		(state) => state.settings
	);
	const [isPalletAvailable, setIsPalletAvailable] = useState(false);
	const [isReversed, setIsReversed] = useState(false);
	const [orientation, setOrientation] = useState(
		Number(localStorage.getItem("orientation") ?? 90)
	);
	const [orientationArrow, setOrientationArrow] = useState(
		Number(localStorage.getItem("orientationArrow") ?? 90)
	);
	const [rotate, setRotate] = useState(
		Number(localStorage.getItem("rotate") ?? 0)
	);
	const [rotateArrow, setRotateArrow] = useState(
		Number(localStorage.getItem("rotateArrow") ?? 0)
	);
	const isSmall = useMediaQuery("(max-width: 1200px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");

	let angle = [0, 90, 180, 270];

	const isChartDisplayed = isChartDisplayedSetting && rfidMasks.length < 2;
	const isTableDisplayed = isTableDisplayedSetting && rfidMasks.length < 2;

	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getRfidData());
	}, []);

	useEffect(() => {
		return () => {
			localStorage.setItem("rotate", rotate);
			localStorage.setItem("rotateArrow", rotateArrow);
			localStorage.setItem("orientation", orientation);
			localStorage.setItem("orientationArrow", orientationArrow);
		};
	});

	useEffect(() => {
		if (uuid) {
			setIsPalletAvailable(true);
			dispatch(getRfidUpdates(uuid));
		}
	}, [uuid]);

	const refreshAt = useCallback((hours, minutes, seconds) => {
		const now = new Date();
		const then = new Date();

		if (
			now.getHours() > hours ||
			(now.getHours() === hours && now.getMinutes() > minutes) ||
			(now.getHours() === hours &&
				now.getMinutes() === minutes &&
				now.getSeconds() >= seconds)
		) {
			then.setDate(now.getDate() + 1);
		}
		then.setHours(hours);
		then.setMinutes(minutes);
		then.setSeconds(seconds);

		const timeout = then.getTime() - now.getTime();
		setTimeout(function () {
			window.location.reload(true);
		}, timeout);
	}, []);

	useEffect(() => {
		refreshAt(12, 0, 0);
	}, [refreshAt]);

	useEffect(() => {
		refreshAt(0, 0, 0);
	}, [refreshAt]);

	const rotate90 = () => {
		setRotate((prev) => (prev < 3 ? prev + 1 : 0));
		setRotateArrow((prev) => (prev < 3 ? prev + 1 : 0));
	};

	const flip = () => {
		setOrientation((prev) => (prev === 90 ? 270 : 90));
		setOrientationArrow((prev) => (prev === 90 ? 270 : 90));
	};

	const rotate90Arrow = () => {
		setRotateArrow((prev) => (prev < 3 ? prev + 1 : 0));
	};

	const flipArrow = () => {
		setOrientationArrow((prev) => (prev === 90 ? 270 : 90));
	};

	const chartElement = useMemo(
		() =>
			isChartDisplayed &&
			scheme && (
				<ChartWrapper>
					<DefectsChart isDrySide={true} chart={scheme.chart} />
				</ChartWrapper>
			),
		[scheme, isChartDisplayed]
	);

	if (!isPalletAvailable)
		return (
			<Box
				width="100vw"
				height="94vh"
				display={"flex"}
				flexDirection="row"
				gap="3rem"
				justifyContent="center"
				alignItems="center"
			>
				<Box width={"650px"}>
					{rfidData && isTableDisplayed && (
						<RfidTable rows={rfidData} />
					)}
				</Box>
				<Typography fontSize={32}>{t("rfid.wait")}</Typography>
				<Box width="650px"></Box>
			</Box>
		);

	if (isPortrait || isSmall) {
		return (
			<Box display={"flex"} flexDirection={"column"}>
				<Box padding={"16px 16px"}>
					{isTableDisplayed && (
						<Table sx={{ borderRadius: "6px", boxShadow: "none" }}>
							<TableHead>
								<TableRow>
									<TableCell
										sx={{
											fontSize: "2.5vw",
										}}
									>
										{t("rfid.ID")}
									</TableCell>
									<TableCell sx={{ fontSize: "2.5vw" }}>
										{t("rfid.time")}
									</TableCell>
									<TableCell align="left">
										{t("rfid.deprecation")}
									</TableCell>
									<TableCell sx={{ fontSize: "2.5vw" }}>
										{t("rfid.pallet")}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rfidData
									.slice(0, rfidMasks.length)
									.map((data) => (
										<TableRow key={data.tag_id}>
											<TableCell
												sx={{ fontSize: "2.5vw" }}
											>
												{data?.tag_id}
											</TableCell>
											<TableCell
												sx={{ fontSize: "2.5vw" }}
											>
												{data?.dt
													?.split("+")[0]
													?.split("T")[1]
													?.slice(0, 8)}
												(
												{
													data?.dt
														?.split("+")[0]
														?.split("T")[0]
												}
												)
											</TableCell>
											<TableCell
												sx={{ fontSize: "2.5vw" }}
											>
												{data?.rounds}
											</TableCell>
											<TableCell
												sx={{ fontSize: "2.5vw" }}
											>
												{data?.last_paired_with ?? ""}
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					)}
					<Button
						sx={{
							marginTop: "1rem",
							fontSize: "2vw",
						}}
						onClick={flip}
					>
						{t("dry.btn")}
					</Button>
				</Box>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					flexDirection={"column"}
					gap={"1rem"}
					height={"calc((100vw / 11) * 14)"}
					width={"100%"}
				>
					{rfidMasks.map((rfidMask, index) => (
						<RotatedImage
							key={rfidMask?.sectors[0]?.id}
							orientation={orientation}
						>
							<SvgWrapper>
								<image href={rfidImages[index]} />
								{rfidMask &&
									rfidMask?.defect_stones?.length > 0 &&
									rfidMask?.defect_stones?.map((stone) => (
										<g key={stone?.id}>
											<polygon
												id={`${stone?.id}`}
												fill="#00000000"
												stroke={"#eb3434"}
												strokeWidth={"8px"}
												points={stone?.points}
											/>
										</g>
									))}
								{rfidMask?.repeated_defect_stones?.length >
								0 ? (
									rfidMask?.repeated_defect_stones?.map(
										(stone) => (
											<g key={stone?.id}>
												<polygon
													id={`${stone?.id}`}
													fill="#00000000"
													stroke={"#873FA0"}
													strokeWidth={"5px"}
													points={stone?.points}
												/>
											</g>
										)
									)
								) : (
									<></>
								)}
								{rfidMask?.repeated_height_stones?.length >
								0 ? (
									rfidMask?.repeated_height_stones?.map(
										(stone) => (
											<g key={stone?.id}>
												<polygon
													id={`${stone?.id}`}
													fill="#00000000"
													stroke={"#873FA0"}
													strokeWidth={"5px"}
													points={stone?.points}
												/>
											</g>
										)
									)
								) : (
									<></>
								)}
								{rfidMask?.sectors.map((mask) => (
									<g key={mask?.id}>
										<g>
											<filter
												id="rounded-corners"
												x="-5%"
												width="110%"
												y="0%"
												height="100%"
											>
												<feFlood floodColor="#353535" />
												<feGaussianBlur stdDeviation="2" />
												<feComponentTransfer>
													<feFuncA
														type="table"
														tableValues="0 0 0 1"
													/>
												</feComponentTransfer>

												<feComponentTransfer>
													<feFuncA
														type="table"
														tableValues="0 1 1 1 1 1 1 1"
													/>
												</feComponentTransfer>
												<feComposite
													operator="over"
													in="SourceGraphic"
												/>
											</filter>
											<text
												filter="url(#rounded-corners)"
												fill={"#ff0000"}
												fontSize={32}
												textAnchor={"middle"}
												alignmentBaseline={"middle"}
												stroke="black"
												strokeWidth={0.2}
												x={
													mask?.center_text_x_y
														?.split(" ")
														?.map((split) =>
															split.slice(2)
														)
														?.map((split) =>
															Number(split)
														)[0]
												}
												y={
													mask?.center_text_x_y
														?.split(" ")
														?.map((split) =>
															split.slice(2)
														)
														?.map((split) =>
															Number(split)
														)[1]
												}
												transform-origin={mask?.center_text_x_y?.replace(
													/x=|y=/g,
													""
												)}
												transform={`rotate(-${angle[orientation]})`}
											>
												{mask?.heights_repeat_count >
													0 && mask?.value}
											</text>
											<polygon
												id={`${mask?.id}`}
												fill="#00000000"
												stroke={"white"}
												strokeWidth={"1px"}
												points={mask?.points}
											/>
										</g>
									</g>
								))}
							</SvgWrapper>
						</RotatedImage>
					))}
				</Box>
				<KeyboardDoubleArrowDownIcon
					onClick={flipArrow}
					style={{
						transform: `rotate(${orientationArrow}deg)`,
						width: "8rem",
						height: "8rem",
						aspectRatio: "1/1",
					}}
				/>
			</Box>
		);
	}

	return (
		<Box
			display={"flex"}
			flexDirection="column"
			justifyContent="center"
			alignItems={"center"}
			padding={"2rem 0"}
		>
			<Box
				display={"flex"}
				flexDirection={"row"}
				gap="10px"
				alignItems={"center"}
			>
				<Button onClick={() => setIsReversed((state) => !state)}>
					<CompareArrowsIcon />
				</Button>
			</Box>
			<Box
				display={"flex"}
				width={"100%"}
				justifyContent={"center"}
				padding={"0 3rem"}
				flexDirection={isReversed ? "row" : "row-reverse"}
				gap="2rem"
			>
				{isTableDisplayed || isChartDisplayed ? (
					<Box
						display="flex"
						flexDirection={"column"}
						width="40vw"
						mt="40px"
						gap="20px"
					>
						{rfidData && scheme ? (
							<>
								{isTableDisplayed && (
									<RfidTable
										rows={rfidData?.slice(
											0,
											rfidMasks.length
										)}
									/>
								)}
								{chartElement}
							</>
						) : (
							""
						)}
					</Box>
				) : null}
				<Box
					display={"flex"}
					flex={"1"}
					flexDirection="column"
					gap="1rem"
					maxWidth={rfidMasks.length > 1 ? "90vw" : "54vw"}
					alignItems={"center"}
				>
					<Button
						sx={{
							fontSize: "0.875rem",
						}}
						onClick={rotate90}
					>
						{t("dry.btn")}
					</Button>
					<PalletViewsWrapper isReversed={isReversed}>
						{rfidMasks.map((rfidMask, index) => (
							<PalletContainer
								isRotated={
									angle[rotate] === 90 ||
									angle[rotate] === 270
								}
								key={rfidMask?.sectors[0]?.id}
							>
								<SvgWrapper
									style={{
										transform: `rotate(${
											angle[rotate]
										}deg) scale(${
											angle[rotate] === 90 ||
											angle[rotate] === 270
												? "0.92"
												: "1"
										})`,
										marginTop:
											rotate === 1 || rotate === 3
												? "50px"
												: "0px",
									}}
								>
									<image href={rfidImages[index]} />
									{rfidMask &&
										rfidMask?.defect_stones?.length > 0 &&
										rfidMask?.defect_stones?.map(
											(stone) => (
												<g key={stone.id}>
													<polygon
														id={`${stone?.id}`}
														fill="#00000000"
														stroke={"#eb3434"}
														strokeWidth={"8px"}
														points={stone?.points}
													/>
												</g>
											)
										)}
									{rfidMask?.repeated_defect_stones?.length >
									0 ? (
										rfidMask?.repeated_defect_stones?.map(
											(stone) => (
												<g key={stone?.id}>
													<polygon
														id={`${stone?.id}`}
														fill="#00000000"
														stroke={"#873FA0"}
														strokeWidth={"5px"}
														points={stone?.points}
													/>
												</g>
											)
										)
									) : (
										<></>
									)}
									{rfidMask?.repeated_height_stones?.length >
									0 ? (
										rfidMask?.repeated_height_stones?.map(
											(stone) => (
												<g key={stone?.id}>
													<polygon
														id={`${stone?.id}`}
														fill="#00000000"
														stroke={"#873FA0"}
														strokeWidth={"5px"}
														points={stone?.points}
													/>
												</g>
											)
										)
									) : (
										<></>
									)}
									{rfidMask?.sectors?.map((mask) => (
										<g key={mask?.id}>
											<g>
												<filter
													id="rounded-corners"
													x="-5%"
													width="110%"
													y="0%"
													height="100%"
												>
													<feFlood floodColor="#353535" />
													<feGaussianBlur stdDeviation="2" />
													<feComponentTransfer>
														<feFuncA
															type="table"
															tableValues="0 0 0 1"
														/>
													</feComponentTransfer>

													<feComponentTransfer>
														<feFuncA
															type="table"
															tableValues="0 1 1 1 1 1 1 1"
														/>
													</feComponentTransfer>
													<feComposite
														operator="over"
														in="SourceGraphic"
													/>
												</filter>
												<text
													filter="url(#rounded-corners)"
													fill={"#ff0000"}
													fontSize={32}
													textAnchor={"middle"}
													alignmentBaseline={"middle"}
													stroke="black"
													strokeWidth={0.2}
													x={
														mask?.center_text_x_y
															?.split(" ")
															?.map((split) =>
																split.slice(2)
															)
															?.map((split) =>
																Number(split)
															)[0]
													}
													y={
														mask?.center_text_x_y
															?.split(" ")
															?.map((split) =>
																split.slice(2)
															)
															?.map((split) =>
																Number(split)
															)[1]
													}
													transform-origin={mask?.center_text_x_y?.replace(
														/x=|y=/g,
														""
													)}
													transform={`rotate(-${angle[rotate]})`}
												>
													{mask?.heights_repeat_count >
														0 && mask?.value}
												</text>
												<polygon
													id={`${mask?.id}`}
													fill="#00000000"
													stroke={"white"}
													strokeWidth={"1px"}
													points={mask?.points}
												/>
											</g>
										</g>
									))}
								</SvgWrapper>
								{rfidMasks.length > 1 ? (
									<OrderNumber>{index + 1}</OrderNumber>
								) : null}
							</PalletContainer>
						))}
					</PalletViewsWrapper>
				</Box>
				<KeyboardDoubleArrowDownIcon
					onClick={rotate90Arrow}
					style={{
						transform: `rotate(${angle[rotateArrow]}deg)`,
						flex: "0.1 1 0%",
						aspectRatio: "1/1",
						height: "fit-content",
						maxWidth: "8rem",
						color: "#4357A0",
					}}
				/>
			</Box>
		</Box>
	);
};

export default DrySide;
