import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import ModalForDefects from "../UI/ModalForDefects";
import DefectTooltip from "../UI/DefectTooltip";

export default function ColorDefectTable({ list }) {
	const [openTooltip, setOpenTooltip] = React.useState(false);
	const [selectedColor, setSelectedColor] = React.useState(null);
	const { t } = useTranslation();

	const btnHandler = (color) => {
		setSelectedColor(color);
		setOpenTooltip(true);
	};

	return (
		<>
			<TableContainer
				sx={{ width: 750, boxShadow: "none", borderRadius: "6px" }}
				component={Paper}
			>
				<Table sx={{ width: 750 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									fontSize: "20px",
									fontWeight: "bold",
									textTransform: "uppercase",
								}}
							>
								{t("settings.defect.color-factory")}
							</TableCell>
							<TableCell sx={{ fontSize: "18px" }} align="center">
								{t("color.crack")}
							</TableCell>
							<TableCell sx={{ fontSize: "18px" }} align="center">
								{t("color.pit")}
							</TableCell>
							<TableCell sx={{ fontSize: "18px" }} align="center">
								{t("color.stain")}
							</TableCell>
							<TableCell sx={{ fontSize: "18px" }} align="center">
								{t("color.destruction")}
							</TableCell>
							<TableCell sx={{ fontSize: "16px" }} align="right">
								<DefectTooltip />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{list?.map((row) => (
							<TableRow
								key={row.color_id}
								sx={{
									"&:last-child td, &:last-child th": {
										border: 0,
									},
								}}
							>
								<TableCell
									sx={{ fontSize: "16px" }}
									component="th"
									scope="row"
								>
									{t(`color.${row.color_name}`)}
								</TableCell>
								<TableCell
									sx={{ fontSize: "16px" }}
									align="center"
								>
									{row.crack_threshold}
								</TableCell>
								<TableCell
									sx={{ fontSize: "16px" }}
									align="center"
								>
									{row.pit_threshold}
								</TableCell>
								<TableCell
									sx={{ fontSize: "16px" }}
									align="center"
								>
									{row.stain_threshold}
								</TableCell>
								<TableCell
									sx={{ fontSize: "16px" }}
									align="center"
								>
									{row.destruction_threshold}
								</TableCell>
								<TableCell align="right">
									<Button
										sx={{
											color: "#7CC6B6",
											border: "1px solid #7CC6B6",
											borderRadius: "10px",
											p: "4px 12px 4px 12px",
										}}
										onClick={() => btnHandler(row)}
									>
										{t("color.change")}
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{openTooltip && (
				<ModalForDefects
					open={openTooltip}
					onClose={() => setOpenTooltip(false)}
					uuid={selectedColor?.color_id}
					crack_threshold={selectedColor?.crack_threshold}
					pit_threshold={selectedColor?.pit_threshold}
					stain_threshold={selectedColor?.stain_threshold}
					destruction_threshold={selectedColor?.destruction_threshold}
					name={selectedColor?.color_name}
				/>
			)}
		</>
	);
}
